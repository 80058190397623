<template>
    <div>
      <PageTitle>
        {{ title }}
      </PageTitle>
      <IndexPage
        :resource="sms"
        :disable_mass_operations="true"
        :disable_add_new="true"
        :withExtensionSelector="true"
        :extensionCb="'load_saved_filters'"
        :allow_account_level="true"
        :label_cols="label_cols"
        class="sms list-page"
        data-test-id="reports-sms-page"
      >
          <template v-slot:filters>
            <SmartFilters
              :log="sms"
              :label_cols="label_cols"
              :key="JSON.stringify(sms.client_config)"
              data-test-id="sms-smart-filters"
            />
            <FormInput
              :label="$lang.t('reports.call-direction', 'Call direction')"
              :label_cols="label_cols"
            >
              <w-select
                v-model="sms.filters.direction"
                @change="sms.restartSavedFilterName()"
                :items="[
                  {value: null,text: $lang.t('app.all-calls', 'All calls')},
                  {value: 'in',text: $lang.t('app.inbound', 'Inbound')},
                  {value: 'out',text: $lang.t('app.outbound', 'Outbound')},
                ]"
                hide-details="auto"
                data-test-id="call-reports-filters-group-bu-select"
              />
            </FormInput>
            <FormInput :label="$lang.t('app.from-number', 'From number')" :label_cols="label_cols">
              <Tel
                @changed="sms.filter_param_changed('from', $event.clean_number)"
                :value="sms.filters.from"
                :key="sms.saved_filter_name"
                data-test-id="sms-filters-from-input"
              />
            </FormInput>
            <FormInput :label="$lang.t('app.to-number', 'To number')" :label_cols="label_cols">
              <Tel
                @changed="sms.filter_param_changed('to', $event.clean_number)"
                :value="sms.filters.to"
                :key="sms.saved_filter_name"
                data-test-id="sms-filters-to-input"
              />
            </FormInput>
          </template>
          <template v-slot:additionalFilterButtons v-if="sms.items.length">
            <SaveFilter
              v-if="sms.filters_applied && !sms.saved_filter_name"
              :generate_name_callback="sms.generateNewFilterName.bind(sms)"
              @confirmed="sms.save_filters(null, $event)"
              data-test-id="sms-save-filter"
            />
            <w-btn type="button" @click="sms.generate_csv()" color="primary" class="primary--text text--darken-1 primary lighten-5 mr-5" data-test-id="sms-get-csv-btn">
                {{ $lang.t("app.get-csv", "Get CSV") }}
            </w-btn>
          </template>
          <template v-slot:list>
            <v-simple-table data-test-id="sms-table">
              <thead data-test-id="table-head">
                <tr data-test-id="head-row">
                    <th data-test-id="id">{{ $lang.t("sms.message-id", "Message ID") }}</th>
                    <th data-test-id="extension">{{ $lang.t("app.extension", "Extension") }}</th>
                    <th data-test-id="from">{{ $lang.t("app.from", "From") }}</th>
                    <th data-test-id="to">{{ $lang.t("app.to", "To") }}</th>
                    <th data-test-id="direction">{{ $lang.t("app.direction", "Direction") }}</th>
                    <th data-test-id="status">{{ $lang.t("sms.delivery-status", "Delivery status") }}</th>
                    <th data-test-id="sent-at">{{ $lang.t("app.sent-at", "Sent at") }}</th>
                    <!-- <th>{{ $lang.t('app.segments', 'Segments') }}</th> -->
                </tr>
              </thead>
              <tbody data-test-id="table-body">
                <tr v-for="(message, i) in sms.items" :data-test-id="`sms-item-${message.id}-row`" :key="`sms-item-${message.id}-row`">
                    <td data-test-id="id">{{ message.id }}</td>
                    <td data-test-id="extension">{{ message.extension ? `#${message.extension.extension}` : "—" }}</td>
                    <td data-test-id="from">{{ message.from | formatPhoneNumber }}</td>
                    <td data-test-id="to">{{ message.to | formatPhoneNumber }}</td>
                    <td :data-test-id="`sms-item-${message.id}-direction`">
                      {{ message.direction === 'in' ? $lang.t('app.inbound', 'Inbound') : $lang.t('app.outbound', 'Outbound')}}
                    </td>
                    <td :data-test-id="`sms-item-${message.id}-status`">{{ status(message.status) }}</td>
                    <td data-test-id="sent-at">{{ message.sent_at | format_date }}</td>
                    <!-- <td>{{ message.segments }}</td> -->
                </tr>
              </tbody>
            </v-simple-table>
          </template>
      </IndexPage>
    </div>
  </template>
  
  <script>
    import SMS from '../../models/SMS';
    import {formatTime} from 'formatters';
    import {vueComponent} from 'helpers';
    import SidebarRouter from 'console/src/libs/sidebar-router'
    import Tel from 'console/src/components/elements/Tel.vue';
    import IndexPage from 'console/src/components/elements/IndexPage.vue';
    import PageTitle from 'console/src/components/elements/PageTitle.vue';
    import FormInput from 'console/src/components/elements/form/FormInput.vue';
    import SmartFilters from 'console/src/components/elements/SmartFilters.vue';
    import SaveFilter from 'console/src/components/elements/modal/SaveFilter.vue';
    import ExtensionSelector from 'console/src/components/fragments/ExtensionSelector.vue';
    export default {
      components: {
        Tel,
        FormInput,
        PageTitle,
        IndexPage,
        SaveFilter,
        SmartFilters,
        ExtensionSelector,
      },
      data() {
        return {
          sms: new SMS(this.$session, vueComponent(this)),
          label_cols: 2,
        };
      },
      methods: {
        status(val) {
          if (val === 'delivered') {
            return this.$lang.t('app.delivered', 'Delivered');
          }
          if (val === 'failed') {
            return this.$lang.t('app.failed', 'Failed');
          }
  
          return val;
        }
      },
      computed: {
        title() {
          const sms = SidebarRouter.report_routes.routes.find((x) => x.params && x.params.type === 'sms')
          if (sms) return sms.long_title

          return 'SMS';
        }
      },
      filters: {
        format_date(val) {
          return formatTime(val).replace(',', ' ');
        },
      },
    };
  </script>
  