import React, { Component } from 'react'

import Button from 'button'

import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withStyles } from '@material-ui/core'
import LoaderFull from 'loader-full'
import { theme } from 'get-theme'
import PropTypes from 'prop-types'
import { XIcon } from 'pdc-svg-icons'
import Spinner from 'spinner'

const styles = theme => ({
    loadingDiv: theme.loadingDiv,
    dialog: Object.assign({
        '&.no-scroll': {
            overflow: 'unset'
        }
    }, theme.confirmDialog.paper),
    dialogTitle: theme.confirmDialog.dialogTitle,
    dialogSubTitle: theme.confirmDialog.dialogSubTitle,
    dialogContent: Object.assign({
        '&.no-scroll': {
            overflow: 'unset'
        }
    }, theme.confirmDialog.dialogContent),
    dialogFooter: theme.confirmDialog.dialogFooter,
    xIcon: theme.confirmDialog.xIcon,
    rejectButton: {
        cursor: 'pointer',
        width: 'fit-content',
        '&:not(:hover) .hover': {
            display: 'none'
        },
        '&:hover .enabled': {
            display: 'none'
        }
    },
    confirmButton: {
        cursor: 'pointer',
        marginLeft: '20px !important',
        width: 'fit-content',
        '&:not(:hover) .hover': {
            display: 'none'
        },
        '&:not(.disabled):hover  .enabled': {
            display: 'none'
        }
    },
    confirmSpinner: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: 50,
        '& svg': {
            color: theme.palette.primary[0]
        }
    }
})

class ConfirmModal extends Component {
    constructor (props) {
        super(props)
        this.state = {
            title: props.title,
            subTitle: props.subTitle,
            content: props.content,
            loading: props.loading
        }
    }

    componentDidUpdate () {
        if (!this.props.isShown) return
        if (this.props.title === this.state.title && this.props.content === this.state.content && this.props.subTitle === this.state.subTitle) return
        this.setState({
            title: this.props.title,
            subTitle: this.props.subTitle,
            content: this.props.content,
            loading: this.props.loading
        })
    }

    renderLoader = () => {
        const { classes } = this.props
        return (
            <div className={classes.loadingDiv}>
                <LoaderFull text='Please wait...' color={theme.palette.secondary[0]} size='bigger'/>
            </div>
        )
    }

    render () {
        const { classes, isShown, noScroll, showLoader, titleInfoTip } = this.props
        const noButtonDataTestId = this.props.noButtonDataTestId || 'no-modal-button'
        const yesButtonDataTestId = this.props.yesButtonDataTestId || 'yes-modal-button'
        const noScrollClass = noScroll ? 'no-scroll' : ''
        const dialogSubTitle = this.state.subTitle ? <DialogTitle classes={{ root: classes.dialogSubTitle }}><div className='subTitle'>{this.state.subTitle}</div></DialogTitle> : null
        const content = this.state.loading ? <div className={classes.confirmSpinner}><Spinner color='action'/></div> : this.state.content
        return (
            <Dialog
                onClose={this.props.onReject}
                disableBackdropClick
                open = {isShown}
                classes = {{ paper: `${classes.dialog} ${this.props.size || ''} ${noScrollClass}` }}
                fullScreen = {this.props.size === 'sizeMobile'}
            >
                <div className={`${classes.xIcon} ${this.props.size === 'sizeMobile' ? 'small' : ''}`} onClick={this.props.isShown ? this.props.onReject : null}><XIcon/></div>
                <DialogTitle disableTypography classes={{ root: `${classes.dialogTitle} ${this.props.noTitleMargin ? 'noTitleMargin' : ''}` }}>
                    <h5>{this.state.title}{titleInfoTip ? <>&nbsp;{this.props.titleInfoTip}</> : null}</h5>
                </DialogTitle>
                {dialogSubTitle}
                <DialogContent classes={{ root: `${classes.dialogContent} ${noScrollClass}` }}>
                    {content}
                </DialogContent>
                <DialogActions classes={{ root: `${classes.dialogFooter} ${this.props.size === 'sizeMobile' ? 'small' : ''}` }}>
                    <Button
                        className = {classes.rejectButton}
                        onClick = {this.props.isShown ? this.props.onReject : null}
                        color = {this.props.noButtonColor || 'secondary'}
                        disabled = {Boolean(this.props.rejectButtonDisabled)}
                        data-test-id = {noButtonDataTestId}
                    >
                        {this.props.noButtonText || 'No'}
                    </Button>
                    <Button
                        className = {classes.confirmButton}
                        onClick = {this.props.isShown ? this.props.onConfirm : null}
                        color = {this.props.yesButtonColor || 'primary'}
                        disabled = {Boolean(this.props.confirmButtonDisabled)}
                        data-test-id = {yesButtonDataTestId}
                        buttonRef = {this.props.confirmRef}
                    >
                        {this.props.yesButtonText || 'Yes'}
                    </Button>
                </DialogActions>
                {showLoader ? this.renderLoader() : null}
            </Dialog>
        )
    }
}

ConfirmModal.propTypes = {
    // Material ui classes
    classes: PropTypes.object,
    /**
     * Is the modal shown
     */
    isShown: PropTypes.bool,
    /**
     * The title of the modal.
     */
    title: PropTypes.any,
    /**
     * The content of the modal
     */
    content: PropTypes.any,
    /**
     * The text in the 'No' / 'Reject' / 'Cancel' button
     */
    noButtonText: PropTypes.string,
    /**
     * The text in the 'Yes' / 'Confirm' / 'Approve' button
     */
    yesButtonText: PropTypes.string,
    /**
     * The color of the 'No' button
     */
    noButtonColor: PropTypes.string,
    /**
     * The color of the 'Yes' button
     */
    yesButtonColor: PropTypes.string,
    /**
     * Should the 'No' button be disabled
     */
    rejectButtonDisabled: PropTypes.bool,
    /**
     * Should the 'Yes' button be disabled
     */
    confirmButtonDisabled: PropTypes.bool,
    /**
     * data-test-id for the 'No' button
     */
    noButtonDataTestId: PropTypes.string,
    /**
     * data-test-id for the 'Yes' button
     */
    yesButtonDataTestId: PropTypes.string,
    /**
     * Function to be called on click on the 'No' button
     */
    onReject: PropTypes.func,
    /**
     * Function to be called on click on the 'Yes' button
     */
    onConfirm: PropTypes.func,
    /**
     * The size of the modal. Sets the maxWidth of the modal. 1 is 330px; 2 is 400px; 440 is 440px; 500 is 500px; 800 is 800px;
     * sizeMobile has padding: 15px
     */
    size: PropTypes.oneOf(['size1', 'size2', 'size440', 'size500', 'size550', 'size800', 'sizeMobile']),
    noTitleMargin: PropTypes.bool,
    noScroll: PropTypes.bool,
    showLoader: PropTypes.bool,
    titleInfoTip: PropTypes.object,
    subTitle: PropTypes.string,
    /**
     * If True displays a spinner and hides the content
     */
    loading: PropTypes.bool,
    confirmRef: PropTypes.object
}

export default withStyles(styles)(ConfirmModal)
