<template>
  <div>
    <w-loader v-if="la.loading || la.appointments_loading" :overlay="la.appointments_loading"/>
    <div v-if="!la.loading || la.appointments_loading" class="show-page">
    <PageTitle class="nowrap">
      {{title()}}
    </PageTitle>
      <w-alert
        v-if="la.alert"
        :message="la.alert.message"
        :level="la.alert.level"
        :closable="la.enable_close_alert"
        @closed="la.alert = null"
        class="mb-6"
      />
      <LiveAnswer
        v-if="la && la.item"
        :_la="la"
        :title="$lang.t('lr.review-update-script', 'Review or update script')"
        @submitted="updateLa"
        @click:cancel="onClickCancel ? onClickCancel() : $emit('click:cancel')"
      />
      <DefaultModal v-model="show_info_popup" :with_x="false" @close="show_info_popup = false" width="400" data-test-id="onvego-info-popup">
        <div class="text-center">
          <v-icon size="40" color="text" class="mb-1">$vuetify.icons.group</v-icon>
          <div class="w-subtitle-1 mb-4">
            {{$lang.t('app.update-installed', 'Update installed')}}
          </div>
        </div>
        {{$lang.t('lr.our-latest-release-improved-the-ux', 'Our latest release includes an improvement to the AI-Connect greeting. Please review your Greeting and edit the text if needed.')}}
        <template v-slot:buttons>
          <w-btn @click="show_info_popup = false" color="secondary">{{$lang.t('app.close', 'Close')}}</w-btn>
        </template>
      </DefaultModal>
    </div>
  </div>
</template>

<script>
  import {vueComponent, vueRedirect} from 'helpers';
  import LA from '../../../console/src/models/LiveAnswer';
  import CookieManager from '../../../console/src/libs/cookie-manager';
  import LiveAnswerVendor from '../../../console/src/models/LiveAnswerVendor';
  import LiveAnswer from '../elements/LiveAnswer.vue';
  import PageTitle from '../../../console/src/components/elements/PageTitle.vue';
  import DefaultModal from '../../../console/src/components/elements/modal/DefaultModal.vue';
  import {getFeatureEnabled} from 'feature-flag';

  export default {
    props: {
      _item: {
        type: Object,
      },
      _id: {
        type: Number,
      },
      postSaveCb: {
        type: Function,
      },
      onClickCancel: {
        type: Function,
      }
    },
    components: {
      LiveAnswer,
      DefaultModal,
      PageTitle,
    },
    data() {
      return {
        la: new LA(this.$session, vueComponent(this)),
        cookie_name: `${this.$session.user.id}-onvego-greeting-popup`,
        show_info_popup: false,
        last_title: '',
      };
    },
    async created() {
      if (this.$props._item || (this.$route && this.$route.params && this.$route.params._item)) {
        const item = this.$props._item || this.$route.params._item;
        if (!this.$data.la.original_item) this.$data.la.original_item = JSON.parse(JSON.stringify(item));
        this.$data.la.item = item;
      } else {
        let id = this.$props._id;
        if (!id) {
          id = this.$route.params.id
        }
        if (id === 'create') {
          return vueRedirect(this, {name: 'not-found'})
        }
        this.$data.la.item = await this.$data.la.getItem(id);
      }
      if (this.$data.la.item && !this.$data.la.item.business_contact) {
        this.$data.la.item.business_contact = {...this.$data.la.empty_business_contact};
      }
      setTimeout(async () => {
        if (await this.pop_can_be_shown()) {
          this.$data.show_info_popup = true;
          const onvego_greeting_note = document.getElementById('onvego-greeting-helper-container');
          if (onvego_greeting_note) {
            setTimeout(() => {
              onvego_greeting_note.scrollIntoView({behavior: 'smooth', block: 'center'});
            }, 1);
          }
        }
      }, 100);
    },
    methods: {
      title() {
        let title = '';
        if (this.$data.la.item) {
          title += this.$lang.t('app.script', 'Script:');
          title += ` ${this.$data.la.item.id ? this.$data.la.item.id.toString() : ''}`;
          title += ` ${this.$data.la.item.name ? this.$data.la.item.name : ''}`;
          this.$data.last_title = title;
        } else {
          title = this.$data.last_title;
        }
        return title;
      },
      async pop_can_be_shown() {
        if (LiveAnswerVendor.vendor_group(this.$data.la.item.vendor) === 'ONVEGO' && !CookieManager.get(this.$data.cookie_name)) {
          const release_date = 1692283568;
          const has_feature = await getFeatureEnabled('console.1.1_call_flow');
          return (this.$data.la.item.updated_at < release_date) && has_feature;
        }

        return false;
      },
      async updateLa(data) {
        const updatedScript = await this.$data.la.update(data);
        if (await this.pop_can_be_shown()) {
          CookieManager.set(this.$data.cookie_name, true, 365 * 10);
          this.$data.show_info_popup = false;
        }
        if (this.$data.la.alert && this.$data.la.alert.level === 'success' && this.$props.postSaveCb) {
          this.$props.postSaveCb(updatedScript);
        }
        this.$data.la.item = null;
      }
    }
  };
</script>
