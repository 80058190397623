<template>
  <div>
    <w-textarea
        v-if="!is_onvego"
        v-bind="$attrs"
        v-on="$listeners"
    />
    <div v-else-if="has_onvego_apointments" ref="onvego_iframe_container" :id="onvego_iframe_container_id"></div>
  </div>
</template>
<script>
import {getFeatureEnabled} from 'feature-flag';
  export default {
    props: {
        is_onvego: {
            type: Boolean,
            default: false,
        },
        iframe_url: {
            type: String,
            default: ''
        },
        item: {
            type: Object,
            required: true,
        }
    },
    data() {
      return {
        onvego_iframe_container_id: 'onvego-calendar',
        has_onvego_apointments: false,
        calendar_initiated: false,
        pdc_onvego_id: 'b00f7160-b1a8-400a-96a1-cc67a0f51bc0',
      };
    },
    async created() {
        this.$data.has_onvego_apointments = await getFeatureEnabled('console.show_onvego_appointments');
    },
    mounted() {
        this.addOnvegoScript();
        this.show_onvego_calendar_if_needed();
        window.addEventListener('message', this.iframeListener);
    },
    beforeDestroy() {
        window.removeEventListener('message', this.iframeListener)
        this.removeOnvegoScript()
    },
    methods: {
        addOnvegoScript() {
            if (this?.$props?.iframe_url && !document.querySelectorAll(`script[src="${this.$props.iframe_url}"]`).length) {

                const onvegoScript = document.createElement('script');
                onvegoScript.setAttribute('src', this.$props.iframe_url);
                document.head.appendChild(onvegoScript);
            }
        },
        removeOnvegoScript() {
            const existingFrames = document.querySelectorAll(`script[src="${this.$props.iframe_url}"]`) || [];
            existingFrames.forEach(frame => frame.remove())

        },
        show_onvego_calendar_if_needed() {
            if (
                !this.$data.calendar_initiated
                && this.$props.is_onvego
            ) {
                
                const loadAppointments = setInterval(() => {
                        if (this.$refs.onvego_iframe_container && window.buildAppointmentsPanel) {
                            window.buildAppointmentsPanel(this.$refs.onvego_iframe_container, this.$session.user.id, this.$props.item.id, this.$data.pdc_onvego_id);
                            this.$data.calendar_initiated = true;
                            clearInterval(loadAppointments)
                        }
                    }, 500);
               setTimeout(()=>{(loadAppointments)? clearInterval(loadAppointments): null}, 10000);
            }
        },
        iframeListener(data) {
            try {
                if (data.data) {
                    let json;
                    try {
                        json = JSON.parse(data.data);
                    } catch (e) {
                        json = data.data;
                    }
                    if (json.action && ['calendar-info-update', 'working-hours-update', 'appointments-update'].includes(json.action)) {
                        this.$emit('change');
                        // let height = 0;
                        // if (json.appointments // default appointments, fired when user opens calendar
                        //     && json.appointments.length === 1
                        //     && json.appointments.every((x) => !x.appointmentType && !x.closingMessage && x.duration === 30)
                        // ) {
                        //     height = 270;
                        // } else {
                        //     const single_appointment_height = 50;
                        //     const default_elements_height = 60 + 16;
                        //     height = default_elements_height + json.appointments.length * single_appointment_height + (json.appointments.length + 1) * 34;
                        // }
                        // if (this.$refs.onvego_iframe_container) {
                        //     this.$refs.onvego_iframe_container.style.height = `${height}px`;
                        // }
                    }
                    if (json.iframeHeight || json.iframeHeight === 0) {
                        this.$refs.onvego_iframe_container.style.height = `${json.iframeHeight}px`;
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    }
  };
</script>
<style scoped lang="scss">
#onvego-calendar{
    height: 270px;
}
</style>
